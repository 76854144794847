var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { Theme } from '../types.js';
import { subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { globalStyles } from '../styles.js';
import { toggleStyles } from './toggle.styles.js';
export const toggleTagName = "dav-toggle";
let Toggle = class Toggle extends LitElement {
    constructor() {
        super(...arguments);
        this.inputClasses = {
            checked: false
        };
        this.sliderClasses = {
            darkTheme: false
        };
        this.theme = Theme.light;
        this.checked = false;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    checkboxClicked(event) {
        event.preventDefault();
        this.dispatchEvent(new CustomEvent("change", {
            detail: {
                checked: this.checked
            }
        }));
    }
    render() {
        // Update the UI based on the properties
        this.inputClasses.checked = this.checked;
        this.sliderClasses.darkTheme = this.theme == Theme.dark;
        return html `
			<label id="switch">
				<input
					class=${classMap(this.inputClasses)}
					type="checkbox"
					?checked=${this.checked}
					@click=${this.checkboxClicked}>
				<span
					id="slider"
					class=${classMap(this.sliderClasses)}>
				</span>
			</label>
		`;
    }
};
Toggle.styles = [globalStyles, toggleStyles];
__decorate([
    state()
], Toggle.prototype, "inputClasses", void 0);
__decorate([
    state()
], Toggle.prototype, "sliderClasses", void 0);
__decorate([
    state()
], Toggle.prototype, "theme", void 0);
__decorate([
    property({ type: Boolean })
], Toggle.prototype, "checked", void 0);
Toggle = __decorate([
    customElement(toggleTagName)
], Toggle);
export { Toggle };
