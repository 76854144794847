var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { getGlobalStyleHtml, getLocale, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { Theme, DropdownOptionType } from '../types.js';
import { globalStyles } from '../styles.js';
import { dropdownStyles } from './dropdown.styles.js';
export const dropdownTagName = "dav-dropdown";
let Dropdown = class Dropdown extends LitElement {
    constructor() {
        super(...arguments);
        this.dropdownLabelClasses = {
            darkTheme: false
        };
        this.dropdownButtonClasses = {
            disabled: false,
            darkTheme: false
        };
        this.dropdownOptionClasses = {
            "dropdown-option": true,
            darkTheme: false
        };
        this.dropdownDividerClasses = {
            "dropdown-divider": true,
            darkTheme: false
        };
        this.dropdownButtonStyles = {
            width: "160px"
        };
        this.dropdownContentStyles = {
            width: "160px",
            display: "none"
        };
        this.locale = getLocale().dropdown;
        this.theme = Theme.light;
        this.showItems = false;
        this.buttonText = this.locale.defaultDropdownButtonText;
        this.label = "";
        this.options = [];
        this.selectedKey = "";
        this.width = 160;
        this.disabled = false;
        this.themeChange = (theme) => this.theme = theme;
        this.documentClick = (event) => {
            if (event.target != this) {
                this.showItems = false;
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
        document.addEventListener("click", this.documentClick);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
        document.removeEventListener("click", this.documentClick);
    }
    dropdownButtonClick() {
        if (!this.disabled) {
            this.showItems = !this.showItems;
        }
    }
    dropdownOptionClick(event) {
        let key = event.target.getAttribute("key");
        if (key == null)
            return;
        this.selectedKey = key;
        this.dispatchEvent(new CustomEvent("change", {
            detail: { key }
        }));
        this.showItems = false;
        this.updateDropdownButtonText();
    }
    updateDropdownButtonText() {
        // Get the selected item and set the button text
        let i = this.options.findIndex(option => option.key == this.selectedKey);
        if (i != -1)
            this.buttonText = this.options[i].value;
        else
            this.buttonText = this.locale.defaultDropdownButtonText;
    }
    getLabel() {
        if (this.label.length == 0) {
            return html ``;
        }
        return html `
			<label
				id="dropdown-label"
				class=${classMap(this.dropdownLabelClasses)}
				for="dropdown-button">
				${this.label}
			</label>
		`;
    }
    getDropdownOption(option) {
        if (option.type == DropdownOptionType.divider) {
            return html `
				<div class=${classMap(this.dropdownDividerClasses)}>
					<hr>
				</div>
			`;
        }
        else {
            return html `
				<button
					class=${classMap(this.dropdownOptionClasses)}
					key=${option.key}
					@click=${this.dropdownOptionClick}>
					${option.value}
				</button>
			`;
        }
    }
    render() {
        // Update the UI based on the properties
        this.dropdownLabelClasses.darkTheme = this.theme == Theme.dark;
        this.dropdownButtonClasses.disabled = this.disabled;
        this.dropdownButtonClasses.darkTheme = this.theme == Theme.dark;
        this.dropdownOptionClasses.darkTheme = this.theme == Theme.dark;
        this.dropdownDividerClasses.darkTheme = this.theme == Theme.dark;
        this.dropdownButtonStyles.width = `${this.width}px`;
        this.dropdownContentStyles.width = `${this.width}px`;
        this.dropdownContentStyles.display = this.showItems ? "block" : "none";
        this.updateDropdownButtonText();
        return html `
			${getGlobalStyleHtml()}

			<div id="dropdown">
				${this.getLabel()}

				<button
					id="dropdown-button"
					class=${classMap(this.dropdownButtonClasses)}
					style=${styleMap(this.dropdownButtonStyles)}
					name="dropdown-button"
					?aria-disabled=${this.disabled}
					@click=${this.dropdownButtonClick}>

					<span>${this.buttonText}</span>

					<i class="ms-Icon ms-Icon--ChevronDown ms-auto" aria-hidden="true"></i>
				</button>

				<div
					id="dropdown-content"
					class="ms-motion-slideDownIn"
					style=${styleMap(this.dropdownContentStyles)}>

					${this.options.map((option) => this.getDropdownOption(option))}
				</div>
			</div>
		`;
    }
};
Dropdown.styles = [globalStyles, dropdownStyles];
__decorate([
    state()
], Dropdown.prototype, "dropdownLabelClasses", void 0);
__decorate([
    state()
], Dropdown.prototype, "dropdownButtonClasses", void 0);
__decorate([
    state()
], Dropdown.prototype, "dropdownOptionClasses", void 0);
__decorate([
    state()
], Dropdown.prototype, "dropdownDividerClasses", void 0);
__decorate([
    state()
], Dropdown.prototype, "dropdownButtonStyles", void 0);
__decorate([
    state()
], Dropdown.prototype, "dropdownContentStyles", void 0);
__decorate([
    state()
], Dropdown.prototype, "locale", void 0);
__decorate([
    state()
], Dropdown.prototype, "theme", void 0);
__decorate([
    state()
], Dropdown.prototype, "showItems", void 0);
__decorate([
    state()
], Dropdown.prototype, "buttonText", void 0);
__decorate([
    property()
], Dropdown.prototype, "label", void 0);
__decorate([
    property({ type: Array })
], Dropdown.prototype, "options", void 0);
__decorate([
    property()
], Dropdown.prototype, "selectedKey", void 0);
__decorate([
    property({ type: Number })
], Dropdown.prototype, "width", void 0);
__decorate([
    property({ type: Boolean })
], Dropdown.prototype, "disabled", void 0);
Dropdown = __decorate([
    customElement(dropdownTagName)
], Dropdown);
export { Dropdown };
