var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { query } from 'lit/decorators/query.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Theme, SidenavMode } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange, convertStringToSidenavMode } from '../utils.js';
import { globalStyles } from '../styles.js';
import { sidenavStyles } from './sidenav.styles.js';
import { showOverlay, hideOverlay } from './sidenav.animations.js';
export const sidenavTagName = "dav-sidenav";
let Sidenav = class Sidenav extends LitElement {
    constructor() {
        super(...arguments);
        this.containerClasses = {
            "modern-vertical-scrollbar": true,
            shadow: true,
            over: false,
            darkTheme: false
        };
        this.overlayStyles = {
            display: "none"
        };
        this.containerStyles = {
            display: "none",
            left: "-300px"
        };
        this.theme = Theme.light;
        this.initialized = false;
        this.open = false;
        this.mode = SidenavMode.side;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    updated(changedProperties) {
        if (this.mode == SidenavMode.over
            && changedProperties.has("open")
            && changedProperties.get("open") != null) {
            let newOpen = !changedProperties.get("open");
            if (newOpen) {
                // Show the overlay
                showOverlay(this.overlay);
            }
            else {
                // Hide the overlay
                hideOverlay(this.overlay).onfinish = () => {
                    this.overlayStyles.display = "none";
                    this.requestUpdate();
                };
            }
        }
    }
    overlayClick() {
        this.dispatchEvent(new CustomEvent("dismiss"));
    }
    render() {
        this.containerClasses.over = this.mode == SidenavMode.over;
        this.containerClasses.darkTheme = this.theme == Theme.dark;
        this.containerStyles.display = this.hidden ? "none" : "block";
        if (this.container != null) {
            if (this.open && this.mode == SidenavMode.over) {
                // Show the container
                this.containerStyles.left = "0";
                this.overlayStyles.display = "block";
            }
            else {
                if (this.initialized) {
                    // Hide the container
                    this.containerStyles.left = `-${this.container.offsetWidth}px`;
                }
                else {
                    this.initialized = true;
                }
            }
        }
        return html `
			${getGlobalStyleHtml()}

			<div
				id="overlay"
				style=${styleMap(this.overlayStyles)}
				@click=${this.overlayClick}>
			</div>

			<div
				id="container"
				class=${classMap(this.containerClasses)}
				style=${styleMap(this.containerStyles)}>

				<slot></slot>
			</div>
		`;
    }
};
Sidenav.styles = [globalStyles, sidenavStyles];
__decorate([
    query("#overlay")
], Sidenav.prototype, "overlay", void 0);
__decorate([
    query("#container")
], Sidenav.prototype, "container", void 0);
__decorate([
    state()
], Sidenav.prototype, "containerClasses", void 0);
__decorate([
    state()
], Sidenav.prototype, "overlayStyles", void 0);
__decorate([
    state()
], Sidenav.prototype, "containerStyles", void 0);
__decorate([
    state()
], Sidenav.prototype, "theme", void 0);
__decorate([
    state()
], Sidenav.prototype, "initialized", void 0);
__decorate([
    property({ type: Boolean })
], Sidenav.prototype, "open", void 0);
__decorate([
    property({
        type: String,
        converter: (value) => convertStringToSidenavMode(value)
    })
], Sidenav.prototype, "mode", void 0);
Sidenav = __decorate([
    customElement(sidenavTagName)
], Sidenav);
export { Sidenav };
