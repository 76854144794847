var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ButtonType } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange, convertStringToButtonType } from '../utils.js';
import { Theme } from '../types.js';
import { globalStyles } from '../styles.js';
import { dialogStyles } from './dialog.styles.js';
export const dialogTagName = "dav-dialog";
let Dialog = class Dialog extends LitElement {
    constructor() {
        super(...arguments);
        this.dialogClasses = {
            shadow: true,
            "ms-motion-slideUpIn": true,
            darkTheme: false
        };
        this.headerClasses = {
            darkTheme: false
        };
        this.containerStyles = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "100"
        };
        this.dialogStyles = {
            maxWidth: "600px"
        };
        this.theme = Theme.light;
        this.dualScreenLayout = false;
        this.header = "";
        this.primaryButtonText = "";
        this.defaultButtonText = "";
        this.visible = false;
        this.loading = false;
        this.primaryButtonType = ButtonType.accent;
        this.maxWidth = 600;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
        let screenSegments;
        if (window["getWindowSegments"]) {
            screenSegments = window["getWindowSegments"]();
        }
        else if (window.visualViewport["segments"]) {
            screenSegments = window.visualViewport["segments"];
        }
        if (screenSegments != null) {
            this.dualScreenLayout = screenSegments.length > 1 && screenSegments[0].width == screenSegments[1].width;
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    overlayClick() {
        if (!this.loading) {
            this.dispatchEvent(new CustomEvent("dismiss"));
        }
    }
    primaryButtonClick() {
        this.dispatchEvent(new CustomEvent("primaryButtonClick"));
    }
    defaultButtonClick() {
        this.dispatchEvent(new CustomEvent("defaultButtonClick"));
    }
    getProgressRing() {
        if (this.loading) {
            return html `
				<dav-progress-ring
					style="width: 16px; height: 16px; margin: 8px 14px 0px 0px;"
					indeterminate="true">
				</dav-progress-ring>
			`;
        }
        else {
            return html ``;
        }
    }
    getDefaultButton() {
        if (this.defaultButtonText.length > 0) {
            return html `
				<dav-button
					?disabled=${this.loading}
					@click=${this.defaultButtonClick}>
					${this.defaultButtonText}
				</dav-button>
			`;
        }
        else {
            return html ``;
        }
    }
    getPrimaryButton() {
        if (this.primaryButtonText.length > 0) {
            return html `
				<dav-button
					style="margin-left: 10px"
					type=${this.primaryButtonType}
					?disabled=${this.loading}
					@click=${this.primaryButtonClick}>
					${this.primaryButtonText}
				</dav-button>
			`;
        }
        else {
            return html ``;
        }
    }
    render() {
        // Update the UI based on the properties
        this.containerStyles.display = this.visible ? "flex" : "none";
        this.containerStyles.left = this.dualScreenLayout ? "50%" : "0";
        this.dialogStyles.maxWidth = `${this.maxWidth}px`;
        this.dialogClasses.darkTheme = this.theme == Theme.dark;
        this.headerClasses.darkTheme = this.theme == Theme.dark;
        return html `
			${getGlobalStyleHtml()}

			<div style=${styleMap(this.containerStyles)}>
				<div
					id="overlay"
					@click=${this.overlayClick}>
				</div>

				<div
					id="dialog"
					class=${classMap(this.dialogClasses)}
					style=${styleMap(this.dialogStyles)}
					role="dialog"
					aria-modal="true"
					aria-live="assertive"
					aria-labelledby="header">

					<h4
						id="header"
						class=${classMap(this.headerClasses)}>
						${this.header}
					</h4>

					<slot></slot>

					<div
						class="d-flex"
						style="float: right">

						${this.getProgressRing()}

						${this.getDefaultButton()}

						${this.getPrimaryButton()}
					</div>
				</div>
			</div>
		`;
    }
};
Dialog.styles = [globalStyles, dialogStyles];
__decorate([
    state()
], Dialog.prototype, "dialogClasses", void 0);
__decorate([
    state()
], Dialog.prototype, "headerClasses", void 0);
__decorate([
    state()
], Dialog.prototype, "containerStyles", void 0);
__decorate([
    state()
], Dialog.prototype, "dialogStyles", void 0);
__decorate([
    state()
], Dialog.prototype, "theme", void 0);
__decorate([
    state()
], Dialog.prototype, "dualScreenLayout", void 0);
__decorate([
    property()
], Dialog.prototype, "header", void 0);
__decorate([
    property()
], Dialog.prototype, "primaryButtonText", void 0);
__decorate([
    property()
], Dialog.prototype, "defaultButtonText", void 0);
__decorate([
    property({ type: Boolean })
], Dialog.prototype, "visible", void 0);
__decorate([
    property({ type: Boolean })
], Dialog.prototype, "loading", void 0);
__decorate([
    property({
        type: String,
        converter: (value) => convertStringToButtonType(value)
    })
], Dialog.prototype, "primaryButtonType", void 0);
__decorate([
    property({ type: Number })
], Dialog.prototype, "maxWidth", void 0);
Dialog = __decorate([
    customElement(dialogTagName)
], Dialog);
export { Dialog };
