var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { Theme } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { globalStyles } from '../styles.js';
import { sidenavItemStyles } from './sidenav-item.styles.js';
export const sidenavItemTagName = "dav-sidenav-item";
let SidenavItem = class SidenavItem extends LitElement {
    constructor() {
        super(...arguments);
        this.spanStyles = {
            marginLeft: "0px"
        };
        this.itemClasses = {
            darkTheme: false
        };
        this.theme = Theme.light;
        this.value = "";
        this.icon = "";
        this.indent = false;
        this.link = null;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    getIcon() {
        if (this.icon.length > 0) {
            return html `
				<i class="ms-Icon ms-Icon--${this.icon}" aria-hidden="true"></i>
			`;
        }
        return html ``;
    }
    itemClick(event) {
        event.preventDefault();
    }
    render() {
        this.itemClasses.darkTheme = this.theme == Theme.dark;
        if (this.icon.length == 0 && this.indent) {
            this.spanStyles.marginLeft = "12px";
        }
        else {
            this.spanStyles.marginLeft = "0px";
        }
        return html `
			${getGlobalStyleHtml()}

			<a id="item"
				class=${classMap(this.itemClasses)}
				dir="ltr"
				href=${ifDefined(this.link)}
				@click=${this.itemClick}>

				${this.getIcon()}

				<span style=${styleMap(this.spanStyles)}>
					${this.value}
				</span>
			</a>
		`;
    }
};
SidenavItem.styles = [globalStyles, sidenavItemStyles];
__decorate([
    state()
], SidenavItem.prototype, "spanStyles", void 0);
__decorate([
    state()
], SidenavItem.prototype, "itemClasses", void 0);
__decorate([
    state()
], SidenavItem.prototype, "theme", void 0);
__decorate([
    property()
], SidenavItem.prototype, "value", void 0);
__decorate([
    property()
], SidenavItem.prototype, "icon", void 0);
__decorate([
    property({ type: Boolean })
], SidenavItem.prototype, "indent", void 0);
__decorate([
    property()
], SidenavItem.prototype, "link", void 0);
SidenavItem = __decorate([
    customElement(sidenavItemTagName)
], SidenavItem);
export { SidenavItem };
