var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getGlobalStyleHtml } from '../utils.js';
import { globalStyles } from '../styles.js';
import { messageBarStyles } from './message-bar.styles.js';
export const messageBarTagName = "dav-message-bar";
let MessageBar = class MessageBar extends LitElement {
    constructor() {
        super(...arguments);
        this.type = "danger";
    }
    getIcon() {
        let iconName = "ErrorBadge";
        if (this.type == "success") {
            iconName = "Completed";
        }
        else if (this.type == "warning") {
            iconName = "Info";
        }
        return html `
			<i class="ms-Icon ms-Icon--${iconName}" aria-hidden="true"></i>
		`;
    }
    render() {
        return html `
			${getGlobalStyleHtml()}

			<div
				class="alert alert-${this.type}"
				style="display: flex; align-items: center; margin-bottom: 0px; font-size: 13px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;"
				role="alert">

				${this.getIcon()}

				<span style="margin-left: 0.75rem">
					<slot></slot>
				</span>
			</div>
		`;
    }
};
MessageBar.styles = [globalStyles, messageBarStyles];
__decorate([
    property()
], MessageBar.prototype, "type", void 0);
MessageBar = __decorate([
    customElement(messageBarTagName)
], MessageBar);
export { MessageBar };
