import { css } from "@lit/reactive-element";
export const toggleStyles = css `
	#switch {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 20px;
	}

	#switch input { 
		opacity: 0;
		width: 0;
		height: 0;
	}

	#slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
		border-radius: 34px;
	}

	#slider.darkTheme {
		background-color: #545e66;
	}

	#slider:before {
		position: absolute;
		content: "";
		height: 11px;
		width: 11px;
		left: 5px;
		top: 4.5px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
		border-radius: 50%;
	}

	input.checked + #slider {
		background-color: #0078d4;
	}

	input:focus + #slider {
		box-shadow: 0 0 1px #0078d4;
	}

	input.checked + #slider:before {
		-webkit-transform: translateX(19px);
		-ms-transform: translateX(19px);
		transform: translateX(19px);
	}
`;
