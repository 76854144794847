export var Theme;
(function (Theme) {
    Theme["light"] = "light";
    Theme["dark"] = "dark";
})(Theme || (Theme = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["default"] = "default";
    ButtonType["accent"] = "accent";
    ButtonType["danger"] = "danger";
})(ButtonType || (ButtonType = {}));
export var DropdownOptionType;
(function (DropdownOptionType) {
    DropdownOptionType[DropdownOptionType["option"] = 0] = "option";
    DropdownOptionType[DropdownOptionType["divider"] = 1] = "divider";
})(DropdownOptionType || (DropdownOptionType = {}));
export var SidenavMode;
(function (SidenavMode) {
    SidenavMode["side"] = "side";
    SidenavMode["over"] = "over";
})(SidenavMode || (SidenavMode = {}));
export var HeaderSize;
(function (HeaderSize) {
    HeaderSize["big"] = "big";
    HeaderSize["normal"] = "normal";
    HeaderSize["small"] = "small";
})(HeaderSize || (HeaderSize = {}));
