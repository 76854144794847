var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { Theme, ButtonType } from '../types.js';
import { convertStringToButtonType, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { globalStyles } from '../styles.js';
import { buttonStyles } from './button.styles.js';
export const buttonTagName = "dav-button";
let Button = class Button extends LitElement {
    constructor() {
        super(...arguments);
        this.buttonClasses = {
            accent: false,
            danger: false,
            disabled: false,
            darkTheme: false
        };
        this.theme = Theme.light;
        this.type = ButtonType.default;
        this.disabled = false;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    buttonClick(event) {
        if (this.disabled) {
            event.stopPropagation();
        }
    }
    render() {
        switch (this.type) {
            case ButtonType.accent:
                this.buttonClasses.accent = true;
                this.buttonClasses.danger = false;
                break;
            case ButtonType.danger:
                this.buttonClasses.accent = false;
                this.buttonClasses.danger = true;
                break;
            default:
                this.buttonClasses.accent = false;
                this.buttonClasses.danger = false;
        }
        this.buttonClasses.disabled = this.disabled;
        this.buttonClasses.darkTheme = this.theme == Theme.dark;
        return html `
			<button
				class=${classMap(this.buttonClasses)}
				?aria-disabled=${this.disabled}
				@click="${this.buttonClick}">
				<slot></slot>
			</button>
		`;
    }
};
Button.styles = [globalStyles, buttonStyles];
__decorate([
    state()
], Button.prototype, "buttonClasses", void 0);
__decorate([
    state()
], Button.prototype, "theme", void 0);
__decorate([
    property({
        type: String,
        converter: (value) => convertStringToButtonType(value)
    })
], Button.prototype, "type", void 0);
__decorate([
    property({ type: Boolean })
], Button.prototype, "disabled", void 0);
Button = __decorate([
    customElement(buttonTagName)
], Button);
export { Button };
