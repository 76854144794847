var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { progressRingStyles } from './progress-ring.styles.js';
export const progressRingTagName = "dav-progress-ring";
let ProgressRing = class ProgressRing extends LitElement {
    render() {
        return html `
			<svg viewBox="0 0 16 16">
				<circle
					style="transform-origin: 50% 50%; animation: spin 2s linear infinite;"
					stroke="#036ac4"
					stroke-width="2px"
					stroke-linecap="round"
					fill="transparent"
					cx="8px"
					cy="8px"
					r="7px">
				</circle>
			</svg>
		`;
    }
};
ProgressRing.styles = [progressRingStyles];
ProgressRing = __decorate([
    customElement(progressRingTagName)
], ProgressRing);
export { ProgressRing };
