var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { checkboxStyles } from './checkbox.styles.js';
import { Theme } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { globalStyles } from '../styles.js';
export const checkboxTagName = "dav-checkbox";
let Checkbox = class Checkbox extends LitElement {
    constructor() {
        super(...arguments);
        this.checkboxClasses = {
            checked: false,
            disabled: false
        };
        this.checkmarkClasses = {
            darkTheme: false
        };
        this.checkboxLabelClasses = {
            disabled: false,
            darkTheme: false,
            empty: false,
            "visually-hidden": false
        };
        this.checkmarkPathStyles = {
            display: "none"
        };
        this.theme = Theme.light;
        this.label = "";
        this.checked = false;
        this.disabled = false;
        this.labelHidden = false;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    checkboxClick() {
        this.toggleCheckbox();
    }
    checkboxKeydown(event) {
        if (event.code == "Space" || event.code == "Enter") {
            event.preventDefault();
            this.toggleCheckbox();
        }
    }
    toggleCheckbox() {
        if (!this.disabled) {
            this.checked = !this.checked;
            this.dispatchEvent(new CustomEvent("change", {
                detail: { checked: this.checked }
            }));
        }
    }
    render() {
        this.checkboxClasses.checked = this.checked;
        this.checkboxClasses.disabled = this.disabled;
        this.checkmarkClasses.darkTheme = this.theme == Theme.dark;
        this.checkboxLabelClasses.disabled = this.disabled;
        this.checkboxLabelClasses.darkTheme = this.theme == Theme.dark;
        this.checkboxLabelClasses.empty = this.label.length == 0;
        this.checkboxLabelClasses['visually-hidden'] = this.labelHidden;
        this.checkmarkPathStyles.display = this.checked ? "unset" : "none";
        return html `
			${getGlobalStyleHtml()}

			<div
				id="checkbox"
				class=${classMap(this.checkboxClasses)}
				tabindex="0"
				role="checkbox"
				?aria-checked=${this.checked}
				?aria-disabled=${this.disabled}
				aria-labelledby="checkbox-label"
				@click=${this.checkboxClick}
				@keydown=${this.checkboxKeydown}>

				<svg
					id="checkmark"
					class=${classMap(this.checkmarkClasses)}
					viewBox="0,0,20,20"
					width="18"
					height="18">

					<path
						style=${styleMap(this.checkmarkPathStyles)}
						d="M8.143 12.6697L15.235 4.5L16.8 5.90363L8.23812 15.7667L3.80005 11.2556L5.27591 9.7555L8.143 12.6697Z"
						fill-rule="evenodd"
						clip-rule="evenodd">
					</path>
				</svg>
			</div>

			<label
				id="checkbox-label"
				class=${classMap(this.checkboxLabelClasses)}
				@click=${this.checkboxClick}>
				${this.label}
			</label>
		`;
    }
};
Checkbox.styles = [globalStyles, checkboxStyles];
__decorate([
    state()
], Checkbox.prototype, "checkboxClasses", void 0);
__decorate([
    state()
], Checkbox.prototype, "checkmarkClasses", void 0);
__decorate([
    state()
], Checkbox.prototype, "checkboxLabelClasses", void 0);
__decorate([
    state()
], Checkbox.prototype, "checkmarkPathStyles", void 0);
__decorate([
    state()
], Checkbox.prototype, "theme", void 0);
__decorate([
    property()
], Checkbox.prototype, "label", void 0);
__decorate([
    property({ type: Boolean })
], Checkbox.prototype, "checked", void 0);
__decorate([
    property({ type: Boolean })
], Checkbox.prototype, "disabled", void 0);
__decorate([
    property({ type: Boolean })
], Checkbox.prototype, "labelHidden", void 0);
Checkbox = __decorate([
    customElement(checkboxTagName)
], Checkbox);
export { Checkbox };
