var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { Theme } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { globalStyles } from '../styles.js';
import { paginationStyles } from './pagination.styles.js';
export const paginationTagName = "dav-pagination";
let Pagination = class Pagination extends LitElement {
    constructor() {
        super(...arguments);
        this.backButtonClasses = {
            "pagination-button": true,
            disabled: false,
            darkTheme: false
        };
        this.forwardButtonClasses = {
            "pagination-button": true,
            disabled: false,
            darkTheme: false
        };
        this.theme = Theme.light;
        this.reducedStart = false;
        this.reducedEnd = false;
        this.pages = 1;
        this.currentPage = 1;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    backButtonClick() {
        if (this.currentPage > 1) {
            this.dispatchEvent(new CustomEvent("pageChange", {
                detail: { page: this.currentPage - 1 }
            }));
        }
    }
    forwardButtonClick() {
        if (this.currentPage < this.pages) {
            this.dispatchEvent(new CustomEvent("pageChange", {
                detail: { page: this.currentPage + 1 }
            }));
        }
    }
    pageButtonClick(index) {
        this.dispatchEvent(new CustomEvent("pageChange", {
            detail: { page: index }
        }));
    }
    getBackButton() {
        if (this.pages <= 0)
            return;
        let disabled = this.currentPage == 1;
        this.backButtonClasses.darkTheme = this.theme == Theme.dark;
        this.backButtonClasses.disabled = disabled;
        return html `
			<button
				id="back-button"
				class=${classMap(this.backButtonClasses)}
				?aria-disabled=${disabled}
				@click=${this.backButtonClick}>
				<i class="ms-Icon ms-Icon--Back" aria-hidden="true"></i>
			</button>
		`;
    }
    getForwardButton() {
        if (this.pages <= 0)
            return;
        let disabled = this.currentPage == this.pages;
        this.forwardButtonClasses.darkTheme = this.theme == Theme.dark;
        this.forwardButtonClasses.disabled = disabled;
        return html `
			<button
				id="forward-button"
				class=${classMap(this.forwardButtonClasses)}
				?aria-disabled=${disabled}
				@click=${this.forwardButtonClick}>
				<i class="ms-Icon ms-Icon--Forward" aria-hidden="true"></i>
			</button>
		`;
    }
    getPageButton(index) {
        if (this.pages > 1) {
            let pageButtonClasses = {
                "pagination-button": true,
                selected: index == this.currentPage,
                darkTheme: this.theme == Theme.dark
            };
            return html `
				<button
					class=${classMap(pageButtonClasses)}
					@click=${() => this.pageButtonClick(index)}>
					${index}
				</button>
			`;
        }
    }
    getButtonPlaceholder() {
        let buttonPlaceholderClasses = {
            "button-placeholder": true,
            darkTheme: this.theme == Theme.dark
        };
        return html `
			<div class=${classMap(buttonPlaceholderClasses)}>
				<span>…</span>
			</div>
		`;
    }
    getPageButtons() {
        let buttons = [];
        let reducedStartAdded = false;
        let reducedEndAdded = false;
        for (let i = 0; i < this.pages; i++) {
            let index = i + 1;
            // Check if the current index is within the reduced start or end
            if (this.reducedStart && index <= this.currentPage - 2 && index != 1) {
                if (!reducedStartAdded) {
                    reducedStartAdded = true;
                    buttons.push(this.getButtonPlaceholder());
                }
            }
            else if (this.reducedEnd && index >= this.currentPage + 2 && index != this.pages) {
                if (!reducedEndAdded) {
                    reducedEndAdded = true;
                    buttons.push(this.getButtonPlaceholder());
                }
            }
            else {
                buttons.push(this.getPageButton(index));
            }
        }
        return buttons;
    }
    render() {
        this.reducedStart = this.pages >= 8 && this.currentPage >= 5;
        this.reducedEnd = this.pages >= 8 && this.currentPage <= (this.pages - 4);
        return html `
			${getGlobalStyleHtml()}

			<div id="container" dir="ltr">
				${this.getBackButton()}

				${this.getPageButtons()}

				${this.getForwardButton()}
			</div>
		`;
    }
};
Pagination.styles = [globalStyles, paginationStyles];
__decorate([
    state()
], Pagination.prototype, "backButtonClasses", void 0);
__decorate([
    state()
], Pagination.prototype, "forwardButtonClasses", void 0);
__decorate([
    state()
], Pagination.prototype, "theme", void 0);
__decorate([
    state()
], Pagination.prototype, "reducedStart", void 0);
__decorate([
    state()
], Pagination.prototype, "reducedEnd", void 0);
__decorate([
    property({ type: Number })
], Pagination.prototype, "pages", void 0);
__decorate([
    property({ type: Number })
], Pagination.prototype, "currentPage", void 0);
Pagination = __decorate([
    customElement(paginationTagName)
], Pagination);
export { Pagination };
