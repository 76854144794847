var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Theme, HeaderSize } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange, convertStringToHeaderSize } from '../utils.js';
import { globalStyles } from '../styles.js';
import { headerStyles } from './header.styles.js';
export const headerTagName = "dav-header";
let Header = class Header extends LitElement {
    constructor() {
        super(...arguments);
        this.headerClasses = {
            "my-0 fw-light text-center": true,
            darkTheme: false
        };
        this.backButtonClasses = {
            "btn icon-button me-3": true,
            darkTheme: false
        };
        this.editButtonClasses = {
            "btn icon-button ms-3": true,
            darkTheme: false
        };
        this.headerStyles = {
            fontSize: "40px"
        };
        this.backButtonStyles = {
            marginTop: "2px"
        };
        this.editButtonStyles = {
            marginTop: "2px"
        };
        this.theme = Theme.light;
        this.header = "";
        this.backButtonVisible = false;
        this.editButtonVisible = false;
        this.size = HeaderSize.normal;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    backButtonClick() {
        this.dispatchEvent(new CustomEvent("backButtonClick"));
    }
    editButtonClick() {
        this.dispatchEvent(new CustomEvent("editButtonClick"));
    }
    getBackButton() {
        if (this.backButtonVisible && this.header.length > 0) {
            return html `
				<button
					id="back-button"
					class=${classMap(this.backButtonClasses)}
					style=${styleMap(this.backButtonStyles)}
					type="button"
					@click=${this.backButtonClick}>
					<i class="ms-Icon ms-Icon--Back" aria-hidden="true"></i>
				</button>
			`;
        }
        return html ``;
    }
    getEditButton() {
        if (this.editButtonVisible && this.header.length > 0) {
            return html `
				<button
					id="edit-button"
					class=${classMap(this.editButtonClasses)}
					style=${styleMap(this.editButtonStyles)}
					type="button"
					@click=${this.editButtonClick}>
					<i class="ms-Icon ms-Icon--Edit" aria-hidden="true"></i>
				</button>
			`;
        }
        return html ``;
    }
    render() {
        this.headerClasses.darkTheme = this.theme == Theme.dark;
        this.backButtonClasses.darkTheme = this.theme == Theme.dark;
        this.editButtonClasses.darkTheme = this.theme == Theme.dark;
        switch (this.size) {
            case HeaderSize.big:
                this.headerStyles.fontSize = "40px";
                this.backButtonStyles.marginTop = "6px";
                this.editButtonStyles.marginTop = "6px";
                break;
            case HeaderSize.normal:
                this.headerStyles.fontSize = "32px";
                this.backButtonStyles.marginTop = "2px";
                this.editButtonStyles.marginTop = "2px";
                break;
            case HeaderSize.small:
                this.headerStyles.fontSize = "28px";
                this.backButtonStyles.marginTop = "-1px";
                this.editButtonStyles.marginTop = "-1px";
                break;
        }
        return html `
			${getGlobalStyleHtml()}

			<h1
				id="header"
				class=${classMap(this.headerClasses)}
				style=${styleMap(this.headerStyles)}
				dir="ltr">
				${this.getBackButton()}
				${this.header}
				${this.getEditButton()}
			</h1>
		`;
    }
};
Header.styles = [globalStyles, headerStyles];
__decorate([
    state()
], Header.prototype, "headerClasses", void 0);
__decorate([
    state()
], Header.prototype, "backButtonClasses", void 0);
__decorate([
    state()
], Header.prototype, "editButtonClasses", void 0);
__decorate([
    state()
], Header.prototype, "headerStyles", void 0);
__decorate([
    state()
], Header.prototype, "backButtonStyles", void 0);
__decorate([
    state()
], Header.prototype, "editButtonStyles", void 0);
__decorate([
    state()
], Header.prototype, "theme", void 0);
__decorate([
    property()
], Header.prototype, "header", void 0);
__decorate([
    property({ type: Boolean })
], Header.prototype, "backButtonVisible", void 0);
__decorate([
    property({ type: Boolean })
], Header.prototype, "editButtonVisible", void 0);
__decorate([
    property({
        type: String,
        converter: (value) => convertStringToHeaderSize(value)
    })
], Header.prototype, "size", void 0);
Header = __decorate([
    customElement(headerTagName)
], Header);
export { Header };
