export function showOverlay(overlay) {
    return overlay.animate([
        {
            opacity: 0
        },
        {
            opacity: 1
        }
    ], {
        duration: 200,
        fill: "forwards"
    });
}
export function hideOverlay(overlay) {
    return overlay.animate([
        {
            opacity: 1
        },
        {
            opacity: 0
        }
    ], {
        duration: 200,
        fill: "forwards"
    });
}
