var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { query } from 'lit/decorators/query.js';
import { styleMap } from 'lit/directives/style-map.js';
import { classMap } from 'lit/directives/class-map.js';
import autosize from 'autosize';
import { Theme } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { globalStyles } from '../styles.js';
import { textareaStyles } from './textarea.styles.js';
export const textareaTagName = "dav-textarea";
let Textarea = class Textarea extends LitElement {
    constructor() {
        super(...arguments);
        this.textareaLabelClasses = {
            disabled: false,
            darkTheme: false
        };
        this.textareaClasses = {
            disabled: false,
            darkTheme: false
        };
        this.textareaStyles = {
            resize: "auto"
        };
        this.theme = Theme.light;
        this.value = "";
        this.label = "";
        this.placeholder = "";
        this.disabled = false;
        this.resize = "";
        this.errorMessage = "";
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
        setTimeout(() => {
            autosize(this.textarea);
        }, 1);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    input() {
        this.value = this.textarea.value;
        this.dispatchEvent(new CustomEvent("change", {
            detail: { value: this.value }
        }));
    }
    getLabel() {
        if (this.label != null && this.label.length > 0) {
            return html `
				<label
					id="textarea-label"
					class=${classMap(this.textareaLabelClasses)}
					for="textarea">
					${this.label}
				</label>
			`;
        }
        return html ``;
    }
    getErrorMessage() {
        if (this.errorMessage != null && this.errorMessage.length > 0) {
            return html `
				<p id="textarea-error-message"
					class="ms-motion-slideDownIn">
					${this.errorMessage}
				</p>
			`;
        }
        return html ``;
    }
    render() {
        this.textareaLabelClasses.disabled = this.disabled;
        this.textareaLabelClasses.darkTheme = this.theme == Theme.dark;
        this.textareaClasses.disabled = this.disabled;
        this.textareaClasses.darkTheme = this.theme == Theme.dark;
        this.textareaStyles.resize = this.resize;
        return html `
			${getGlobalStyleHtml()}

			<div id="textarea-container">
				${this.getLabel()}

				<textarea
					id="textarea"
					style=${styleMap(this.textareaStyles)}
					class=${classMap(this.textareaClasses)}
					name="textarea"
					.value=${this.value}
					?aria-disabled=${this.disabled}
					?readonly=${this.disabled}
					placeholder=${this.placeholder}
					@input=${this.input}>
				</textarea>

				${this.getErrorMessage()}
			</div>
		`;
    }
};
Textarea.styles = [globalStyles, textareaStyles];
__decorate([
    query("#textarea")
], Textarea.prototype, "textarea", void 0);
__decorate([
    state()
], Textarea.prototype, "textareaLabelClasses", void 0);
__decorate([
    state()
], Textarea.prototype, "textareaClasses", void 0);
__decorate([
    state()
], Textarea.prototype, "textareaStyles", void 0);
__decorate([
    state()
], Textarea.prototype, "theme", void 0);
__decorate([
    property()
], Textarea.prototype, "value", void 0);
__decorate([
    property()
], Textarea.prototype, "label", void 0);
__decorate([
    property()
], Textarea.prototype, "placeholder", void 0);
__decorate([
    property({ type: Boolean })
], Textarea.prototype, "disabled", void 0);
__decorate([
    property()
], Textarea.prototype, "resize", void 0);
__decorate([
    property()
], Textarea.prototype, "errorMessage", void 0);
Textarea = __decorate([
    customElement(textareaTagName)
], Textarea);
export { Textarea };
