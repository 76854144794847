export const en = {
    dropdown: {
        defaultDropdownButtonText: "Select an option"
    }
};
export const de = {
    dropdown: {
        defaultDropdownButtonText: "Wähle eine Option"
    }
};
