var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Theme } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { globalStyles } from '../styles.js';
import { contextMenuStyles } from './context-menu.styles.js';
export const contextMenuTagName = "dav-context-menu";
let ContextMenu = class ContextMenu extends LitElement {
    constructor() {
        super(...arguments);
        this.containerClasses = {
            "ms-Fabric": true,
            "ms-motion-slideDownIn": true,
            "ms-depth-16": true,
            visible: false,
            darkTheme: false
        };
        this.containerStyles = {
            top: "0px",
            left: "0px"
        };
        this.theme = Theme.light;
        this.visible = false;
        this.posX = 0;
        this.posY = 0;
        this.themeChange = (theme) => this.theme = theme;
        this.documentClick = (event) => {
            if (event.target != this) {
                this.visible = false;
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
        document.addEventListener("click", this.documentClick);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
        document.removeEventListener("click", this.documentClick);
    }
    render() {
        this.containerClasses.visible = this.visible;
        this.containerClasses.darkTheme = this.theme == Theme.dark;
        this.containerStyles.top = `${this.posX}px`;
        this.containerStyles.left = `${this.posY}px`;
        return html `
			${getGlobalStyleHtml()}

			<div
				id="container"
				class=${classMap(this.containerClasses)}
				style=${styleMap(this.containerStyles)}>
				<slot></slot>
			</div>
		`;
    }
};
ContextMenu.styles = [globalStyles, contextMenuStyles];
__decorate([
    state()
], ContextMenu.prototype, "containerClasses", void 0);
__decorate([
    state()
], ContextMenu.prototype, "containerStyles", void 0);
__decorate([
    state()
], ContextMenu.prototype, "theme", void 0);
__decorate([
    property({ type: Boolean })
], ContextMenu.prototype, "visible", void 0);
__decorate([
    property({ type: Number })
], ContextMenu.prototype, "posX", void 0);
__decorate([
    property({ type: Number })
], ContextMenu.prototype, "posY", void 0);
ContextMenu = __decorate([
    customElement(contextMenuTagName)
], ContextMenu);
export { ContextMenu };
