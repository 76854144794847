var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { query } from 'lit/decorators/query.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { Theme } from '../types.js';
import { globalStyles } from '../styles.js';
import { panelStyles } from './panel.styles.js';
import { slideIn, slideOut } from './panel.animations.js';
export const panelTagName = "dav-panel";
let Panel = class Panel extends LitElement {
    constructor() {
        super(...arguments);
        this.contentClasses = {
            "shadow-lg modern-vertical-scrollbar": true,
            darkTheme: false
        };
        this.closeButtonClasses = {
            darkTheme: false
        };
        this.headerClasses = {
            darkTheme: false
        };
        this.containerStyles = {
            display: "none"
        };
        this.theme = Theme.light;
        this.header = "";
        this.visible = false;
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    updated(changedProperties) {
        if (changedProperties.has("visible") && changedProperties.get("visible") != null) {
            let newIsVisible = !changedProperties.get("visible");
            let animation;
            if (newIsVisible) {
                // Play slide in animation
                animation = slideIn(this.content, this.overlay);
            }
            else {
                // Play slide out animation
                animation = slideOut(this.content, this.overlay);
            }
            animation.onfinish = () => {
                this.containerStyles.display = newIsVisible ? "block" : "none";
                this.requestUpdate();
            };
        }
    }
    overlayClick() {
        this.dispatchEvent(new CustomEvent("dismiss"));
    }
    closeButtonClick() {
        this.dispatchEvent(new CustomEvent("dismiss"));
    }
    render() {
        // Update the UI based on the properties
        this.contentClasses.darkTheme = this.theme == Theme.dark;
        this.closeButtonClasses.darkTheme = this.theme == Theme.dark;
        this.headerClasses.darkTheme = this.theme == Theme.dark;
        if (this.visible) {
            this.containerStyles.display = "block";
        }
        return html `
			${getGlobalStyleHtml()}

			<div
				id="container"
				style=${styleMap(this.containerStyles)}>

				<div
					id="overlay"
					@click=${this.overlayClick}>
				</div>

				<div
					id="content"
					class=${classMap(this.contentClasses)}>

					<button
						id="close-button"
						class=${classMap(this.closeButtonClasses)}
						@click=${this.closeButtonClick}>
						<i class="ms-Icon ms-Icon--Cancel" aria-hidden="true"></i>
					</button>

					<p id="header"
						class=${classMap(this.headerClasses)}>
						${this.header}
					</p>

					<div id="slot-container">
						<slot></slot>
					</div>
				</div>
			</div>
		`;
    }
};
Panel.styles = [globalStyles, panelStyles];
__decorate([
    query("#overlay")
], Panel.prototype, "overlay", void 0);
__decorate([
    query("#content")
], Panel.prototype, "content", void 0);
__decorate([
    state()
], Panel.prototype, "contentClasses", void 0);
__decorate([
    state()
], Panel.prototype, "closeButtonClasses", void 0);
__decorate([
    state()
], Panel.prototype, "headerClasses", void 0);
__decorate([
    state()
], Panel.prototype, "containerStyles", void 0);
__decorate([
    state()
], Panel.prototype, "theme", void 0);
__decorate([
    property()
], Panel.prototype, "header", void 0);
__decorate([
    property({ type: Boolean })
], Panel.prototype, "visible", void 0);
Panel = __decorate([
    customElement(panelTagName)
], Panel);
export { Panel };
