var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { Theme } from '../types.js';
import { getGlobalStyleHtml, subscribeThemeChange, unsubscribeThemeChange } from '../utils.js';
import { globalStyles } from '../styles.js';
import { contextMenuItemStyles } from './context-menu-item.styles.js';
export const contextMenuItemTagName = "dav-context-menu-item";
let ContextMenuItem = class ContextMenuItem extends LitElement {
    constructor() {
        super(...arguments);
        this.buttonClasses = {
            darkTheme: false
        };
        this.theme = Theme.light;
        this.value = "";
        this.icon = "SingleColumn";
        this.themeChange = (theme) => this.theme = theme;
    }
    connectedCallback() {
        super.connectedCallback();
        subscribeThemeChange(this.themeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        unsubscribeThemeChange(this.themeChange);
    }
    render() {
        this.buttonClasses.darkTheme = this.theme == Theme.dark;
        return html `
			${getGlobalStyleHtml()}

			<button dir="ltr" class=${classMap(this.buttonClasses)}>
				<i class="ms-Icon ms-Icon--${this.icon}" aria-hidden="true"></i>
				<span>${this.value}</span>
			</button>
		`;
    }
};
ContextMenuItem.styles = [globalStyles, contextMenuItemStyles];
__decorate([
    state()
], ContextMenuItem.prototype, "buttonClasses", void 0);
__decorate([
    state()
], ContextMenuItem.prototype, "theme", void 0);
__decorate([
    property()
], ContextMenuItem.prototype, "value", void 0);
__decorate([
    property()
], ContextMenuItem.prototype, "icon", void 0);
ContextMenuItem = __decorate([
    customElement(contextMenuItemTagName)
], ContextMenuItem);
export { ContextMenuItem };
